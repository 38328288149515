@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');


body {
  margin: 0;
  font-family: "Baloo 2", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00B2FF;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  height: 12px;
  width: 7px;
  background: rgba(0, 0, 0, 0.459);
}

body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-load{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.white-bento{
  background-color: white;
  border-radius: 30px;
  padding: 70px 100px;
  width: 80%;
  margin: 30px auto;
}

.section-title{
  color: #FFF;
  font-family: "Luckiest Guy";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 0;
  margin-top: 120px;
}

.section-title span{

  font-family: "Baloo Bhai 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
.section-subtitle{
  color: #FFF;
  font-family: "Baloo 2";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 0;
  margin-bottom: 80px;
}

.small-letters{
  font-size: 45px;
  text-transform: inherit;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
}

.logo {
  color: white;
  font-size: 70px;
  text-decoration: none;
  height: 80px;
}

.logo img{
  width: 69px;
}

.logo span{
  font-weight: bolder;
  font-size: 100px;
}

.main-nav .nav-link{
  color: white;
  text-decoration: none;
  font-size: 30px;
  font-weight: bold;
  margin: 0 21px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-block;
}

.main-nav .nav-link:hover{
  color: #C1696B;
}

.socials{
  display: flex;
  justify-content: space-between;
  margin-right: 27px;
  margin-bottom: -17px;
}

.socials a{
  text-decoration: none;
  color: white;
  font-size: 40px;
  margin-left: 30px;
  transition: all 0.3s;
}

.socials a:hover{
  color: #C1696B;
}

.socials-mobile{
  display: none;
}

.hamburger-btn{
  display: none;
}

.hamburger-nav{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 40px 0;
  z-index: 9999;
}

.hamburger-nav .main-nav .nav-link{
    display: block;
    color: black;
    margin-top: 10px;
}

.hamburger-btn-close{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 40px;
  color: #c1696b;
  cursor: pointer;
}

.white-btn{
  background-color: white;
  font-size: 40px;
  font-weight: bold;
  color: #0072FF;
  border: none;
  border-radius: 30px;
  padding: 12px 45px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.white-btn:hover{
  background-color: #C1696B;
}

.white-btn svg{
  font-size: 52px;
  margin-bottom: -15px;
  margin-right: 10px;
}

.two-btn{
  text-align: center;
}

.btn-group .white-btn{
  font-size: 40px;
  font-weight: bold;
  display: block;
  margin: 10px auto 60px auto;
  width: 450px;
  padding: 20px 45px;
  text-align: center;
}

.two-btn .white-btn{
  display: inline-block;
  margin: 0 30px;
}

.cookie-container{
  position: fixed;
  bottom: 5px;
  left: 5px;
  width: 400px;
  height: fit-content;
  z-index: 99999;
  overflow: hidden;
  align-items: center;
  color: black;
  background-color: white;
  border-radius: 30px;
  padding: 30px 30px 0;
}

.cookie-container img{
  width: 120px;
  height: 100px;
  float: right;
  margin-right: 25px;
}

.cookie-container div h2{
  font-size: 18px;
  padding: 0;
}

.cookie-container div p{
  font-size: 12px;
}

.cookie-container div span{
  display: block;
  font-size: 20px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 6px;
  border-top: 1px solid rgb(138, 138, 138);
  cursor: pointer;
  text-align: center;
}

.article-container{
  width: 70%;
  margin: 0 auto;
  background-color: white;
  border-radius: 30px;
  padding: 30px 0;
  margin-bottom: 30px;
}

.content h1{
  font-size: 40px;
  text-align: center;
  margin-bottom: 5px;
  width: 80%;
  margin: 10px auto;
}

.content img{
  margin: 0 auto;
  display: block;
  width: 75%;
}

.content-body{
  display: block;
  margin: 40px auto;
  width: 75%;
  font-size: 19px;
}

.social-icons{
  float: right;
}

.social-icons a{
  color: black;
  text-decoration: none;
  margin-right: 5px;
}

.published-on{
  margin-left: 5px;
}


.disclaimer{
  font-size: 16px;
  color: white;
  font-weight: bold;
  width: 60%;
  display: block;
  margin: 100px auto 15px;
}

.glowing-text{
  background-image: linear-gradient(to right, red , orange);
  background-clip: text;
  color: transparent;
  text-shadow: 0 0 11px rgba(255, 41, 41, 0.493);
}

.carousel {
  width: 100vw;
  height: 82px;
  overflow: hidden;
  margin-top: 40px;

}

.carousel-track {
  display: flex;
  width: fit-content;
  animation: scroll 100s linear infinite;
}

.carousel-slide img {
  width: auto;
  height: 50px;
  margin: 0 20px;
}

.buttons-container{
  display: flex;
  align-items: start;
}

.white-button{
  color: #FF6B00;
  background-color: #FFF;
  font-family: "Baloo 2";
  font-size: 40px;
  height: 58px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 30px;
  padding: 6px 16px;
  display: inline-block;
  margin-right: 30px;
  transition: all 0.3s;
}

.white-button:hover{
  background-color: rgb(144, 180, 211);
}

.orange-button{
  background-color: #FF6B00;
  color: #FFF;
  font-family: "Baloo 2";
  font-size: 40px;
  font-weight: 700;
  padding: 6px 16px;
  height: 58px;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s;
}

.orange-button:hover{
  background-color: #cf5600;
}

.described-button{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content; 
}

.described-button p{
  color: #FFF;
  font-family: "Baloo 2";
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
}

.image-button{
  position: relative;
}

.image-button img{
  width: 100px;
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes scroll {
  0%{
      transform: translateX(0);
  }

  100%{
      transform: translateX(calc(-100% + 100vw + 210px));
  }
}


.chart-container{
  width: 544px;
  height: 546px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.chart{
  width: 64%;
}

.chart canvas{
  width: 100% !important;
  height: 100% !important;
}

.item-label{
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
}
@keyframes showCookie {
  0% {
      bottom: -100%;
  }
  80% {
      bottom: -100%
  }
  100% {
      bottom: 30px
  }
}

.my-slide{
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}

.my-slide img{
  height: 300px;
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
}

.my-slide img:active{
  cursor:grabbing
}

.shremp-togethering{
  width: 100%;
}

/* UrgentWarning.css */
.urgent-warning-container {
  width: 100vw ;
  background-color: white;
  color: black;
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  padding: 10px 0;
  
}

.urgent-warning-text {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.urgent-warning-text .group{
  display: flex;
  align-items: end;

}

.urgent-warning-text h1{
  color: #000;
  font-family: "Luckiest Guy";
  font-size: 30px;
  font-weight: 400;
  display: inline-block;
  margin: 0 30px 0 50px;
}

.timer-container p{
  color: #000;
  font-family: "Luckiest Guy";
  font-size: 35px;
  font-weight: 400;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 12px;
}

.timer{
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.time-field{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #00B2FF;
  border-radius: 23px;
  width: 99px;
  height: 98px;
  margin: 0 2px;
}

.field-name{
  color: #FFF;
  font-family: "Luckiest Guy";
  font-size: 15px;
  font-weight: 400;
}

.field-value{
  color: #FFF;
  font-family: "Luckiest Guy";
  font-size: 50px;
  font-weight: 400;
}

#allert-counter{
  margin: 0;
}

#allert-counter .time-field{
  padding: 0;
  width: fit-content ;
  height: fit-content;
  background-color: transparent;
  margin: 0 20px;
}
#allert-counter .field-name{
  font-size: 12px;
  color: black;
}
#allert-counter .field-value{
  font-size: 30px;
  color: black;
}

.blue-button{
  color: #FFF;
  font-family: "Baloo 2";
  font-size: 20px;
  font-weight: 800;
  border-radius: 50px;
  background: #00B2FF;
  padding: 5px 30px;
  margin-right: 40px;
  text-decoration: none;
  transition: background 0.3s;
}

.blue-button:hover{
  background: #008eca;
}


@media only screen and (max-width: 1500px){
  .content-body,
  .content h1,
  .content img{
      width: 90%;
  }
}

@media only screen and (max-width: 1400px){
  .main-nav{
    display: none;
  }

  .socials{
    display: none;
  }

  .socials-mobile{
    display: block;
  }

  .hamburger-nav .main-nav{
    display: block;
  }

  .hamburger-btn{
    display: block;
    padding: 5px 15px 0;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
  }

  .hamburger-btn svg{
    margin: 0;
    font-size: 45px;
  }

  .buy-nav-btn{
    display: none;
  }

  .white-btn{
    font-size: 35px;
  }

  .cookie-container div button{
      width: 250px;
  }

  .cookie-container div button:last-child{
      margin-top: 10px;
  }
}

@media only screen and (max-width: 1300px){
  .buttons-container{
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px){
 
  .main-nav .nav-link{
    font-size: 30px;
    margin: 0 30px;
  }

 
  .white-btn{
    font-size: 30px;
  }

  .btn-group .white-btn{
    font-size: 30px;
    width: 300px;
  }

  .disclaimer{
    font-size: 14px;
    width: 70%;
  }

  .white-button{
    margin-right: 10px;
  }

  .white-button,
  .orange-button{
    font-size: 35px;
  }

  .described-button p{
    font-size: 14px;
  }

  .image-button img{
    width: 90px;
  }
}

@media only screen and (max-width: 1000px){
  .white-bento{
    padding: 50px 70px;
  }

  .main-nav .nav-link{
    font-size: 25px;
    margin: 0 25px;
  }

  .white-btn{
    font-size: 25px;
  }

  .disclaimer{
    font-size: 12px;
    width: 80%;
  }

  .legal-container{
    width: 90%;
  }

  .article-container{
    width: 90%;
  }
  .white-button,
  .orange-button{
    font-size: 32px;
    height: 48px;
  }

  .described-button p{
    font-size: 13px;
  }

  .image-button img{
    width: 80px;
  }

  #allert-counter{
    margin-left: 30px;
  }

  #allert-counter .time-field{
    margin: 0 5px;
  }
  #allert-counter .field-name{
    font-size: 12px;
  }
  #allert-counter .field-value{
    font-size: 25px;
  }
  .urgent-warning-text h1{
    display: none;
  }

  .blue-button{
    margin-right: 20px;
    padding: 5px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 850px){
  
  .btn-group .white-btn{
    font-size: 18px;
    width: 190px;
  }

  .btn-group .white-btn svg{
    font-size: 30px;
    margin-bottom: -10px;
  }

  .btn-group h3 {
    font-size: 40px;
  }

  .legal-container{
    width: 95%;
  }

  .article-container{
    width: 98%;
  }
  .logo {
    font-size: 46px;
    height: 50px;
  }

  .logo img{
    width: 49px;
  }

  .white-button{
    margin-right: 5px;
  }

  .white-button,
  .orange-button{
    font-size: 28px;
    height: 42px;
  }

  .image-button img{
    width: 75px;
  }
  
}

@media only screen and (max-width: 650px){
  .navbar{
    padding: 20px 20px;
  }
  .white-bento{
    padding: 50px 40px;
  }
  
  .main-nav{
    display: none;
  }

  .socials-mobile{
    margin-right: 0;
  }

  .socials-mobile a:first-child{
    margin-left: 0;
  }

  .socials-mobile a{
    font-size: 30px;
    margin-left: 12px;
  }

  .btn-group .white-btn{
    padding: 10px 10px;
    margin: 5px auto 30px auto;
  }

  .section-title{
    font-size: 30px;
  }

  .small-letters{
    font-size: 25px;
  }

  .section-subtitle{
    font-size: 17px;
    margin-bottom: 40px;
  }
  .disclaimer{
    font-size: 12px;
    width: 98%;
  }

  .white-button,
  .orange-button{
    font-size: 25px;
    height: 35px;
  }

  .described-button p{
    font-size: 10px;
  }

  .image-button img{
    width: 65px;
  }

  .chart-container{
    width: 360px;
    height: 360px;
  }

  .item-label{
    font-size: 15px;
  }
  
  .hamburger-btn svg{
    margin: 0;
    font-size: 35px;
  }
}

@media only screen and (max-width: 500px){
  .orange-button{
    height: 30px;
    font-size: 18px;
    width: 170px;
    text-align: center;
  }
  .white-button{
    height: 30px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 460px) {
  .tokenomics-img img{
    width: 100%;
  }

  .cookie-container{
    width: calc(100% - 30px);
    padding: 10px 10px 0;
  }

  #allert-counter{
    margin-left: 10px;
  }

  #allert-counter .field-name{
    font-size: 8px;
  }

  #allert-counter .field-value{
    font-size: 20px;
  }
  .blue-button{
    margin-right: 10px;
    font-size: 14px;
  }
}

